<template>
  <div class="calendar-trigger">
    <input name="date" id="date" />
    <label for="date"
      ><i class="icon-calendar-1 icon"></i
      ><span class="value">{{ label }}</span></label
    >
  </div>
</template>

<script>
import moment from "moment";
import "moment/locale/ru";
import "../assets/daterangepicker";

export default {
  props: {
    value: Array,
  },
  computed: {
    label() {
      return this.value.map((x) => x.format("DD.MM.YYYY")).join(" – ");
    },
  },
  mounted() {
    const date_input = $('input[name="date"]');
    date_input.daterangepicker(
      {
        startDate: this.value[0],
        endDate: this.value[1],
        ranges: {
          Сегодня: [moment(), moment()],
          Вчера: [moment().subtract(1, "days"), moment().subtract(1, "days")],
          "Последние 3 дня": [moment().subtract(3, "days"), moment()],
          "Последние 7 дней": [moment().subtract(7, "days"), moment()],
          "Текущий месяц": [moment().startOf("month"), moment().endOf("month")],
          "Прошедший месяц": [
            moment().subtract(1, "month").startOf("month"),
            moment().subtract(1, "month").endOf("month"),
          ],
          "Текущий год": [
            moment().startOf("year"),
            moment().endOf("year"),
          ],
          "Прошедший год": [
            moment().subtract(1, "year").startOf("year"),
            moment().subtract(1, "year").endOf("year"),
          ],
        },
      },
      (start, end) => this.$emit("update:value", [start, end])
    );
    date_input.on("click", function () {
      $(this).parents(".calendar-trigger").addClass("_active");
    });
    const handlersFormExist = (e, selector, triggerClass) => {
      if (!$(e.target).closest(selector).length) {
        $(selector).removeClass(triggerClass);
      }
    };
    $(document).on("click", (e) =>
      handlersFormExist(e, ".calendar-trigger", "_active")
    );
  },
};
</script>
