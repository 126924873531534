<template>
  <div
    :class="['filter js-control-filter', { _opened: isOpened }]"
    :data-type="label"
    v-click-outside="() => (isOpened = false)"
  >
    <div
      :class="['filter__header', { _active: isOpened }]"
      @click="isOpened = true"
    >
      <div class="filter__item">
        <p
          :class="[
            'filter__header--title',
            { 'js-selected': this.value.length },
          ]"
          ref="title"
        >
          {{ title }}
        </p>
      </div>
      <i class="icon-down-dir icon"></i>
    </div>
    <div class="filter__body dropdown-body">
      <div class="triangle-with-shadow"></div>
      <input
        class="dropdown-search"
        type="text"
        placeholder="Поиск..."
        @input="$emit('search', $event.target.value)"
        v-if="with_search"
      />
      <div class="dropdown-wrapper">
        <label
          v-for="o of effectiveOptions"
          :key="o.id"
          class="filter__item dropdown-item"
          >
          <span>{{ o.name }}</span>
          <input
            type="checkbox"
            :name="label"
            :value="o.id"
            :checked="value.includes(o.id)"
            @click="toggle(o.id)"
          />
          <div class="check"><i class="icon-ok icon"></i></div>
        </label>
      </div>
      <div class="dropdown-actions">
        <button
          class="dropdown-select_all btn-primary"
          type="button"
          @click.prevent="selectAll"
        >
          Выбрать все
        </button>
        <button
          class="dropdown-accept"
          type="button"
          @click.prevent="isOpened = false"
        >
          <span class="icon-accept icon"><span></span><span></span></span
          >Выбрать
        </button>
        <button class="dropdown-cancel" type="button" @click.prevent="clear">
          <span class="icon-cancel icon"><span></span><span></span></span
          >Очистить
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: String,
    value: Array,
    options: Array,
    with_search: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return { isOpened: false, search: '' };
  },
  // @fixme: title is not displayed in this task because width is set to zero
  // is this code even necessary?
  // mounted() {
  //   const { title } = this.$refs;
  //   title.style.width = title.clientWidth + "px";
  // },
  computed: {
    effectiveOptions() {
      return this.options
        .slice()
        .sort((a, b) => this.value.includes(b.id) - this.value.includes(a.id))
        .slice(0,10);
    },
    title() {
      return this.value.length
        ? this.value
            .map((id) => {
              const o = this.options.find((o) => o.id === id);
              return o ? o.name : null;
            })
            .filter(Boolean)
            .join(", ")
        : this.label;
    },
  },
  methods: {
    selectAll() {
      this.$emit(
        "update:value",
        this.options.map((o) => o.id)
      );
    },
    toggle(id) {
      this.$emit(
        "update:value",
        this.value.includes(id)
          ? this.value.filter((x) => x != id)
          : [...this.value, id]
      );
    },
    clear() {
      this.$emit("update:value", []);
    },
  },
};
</script>
